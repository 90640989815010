import React from "react";
import { store } from "react-notifications-component";
import GraphicEqIcon from "@material-ui/icons/GraphicEq";
import VideocamIcon from "@material-ui/icons/Videocam";
import YouTubeIcon from "@material-ui/icons/YouTube";
import ImageIcon from "@material-ui/icons/Image";
import { Chip, CircularProgress, Tooltip } from "@material-ui/core";
import { darken } from "@material-ui/core/styles";
import { monthNames } from "./data";
import { getQuestionType } from "./questionTypes";
import { rainbow } from "./brandPalette";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCross } from "@fortawesome/free-solid-svg-icons";
import kebabCase from "lodash/kebabCase";
import { getQuestionGroup } from "./questionTypes";
import { getDefaultWidgetStyle } from "./defaults";
import { getDefaultWidgetBehavior } from "./defaults";
import { getDefaultWidgetText } from "./defaults";
var ObjectID = require("bson-objectid");
const queryString = require("query-string");

export function getFrontEndBaseUrl() {
  let BASE_URL = "https://survey.phonic.ai";
  if (process.env.REACT_APP_BACKEND_ENV === "LOCAL") {
    BASE_URL = "http://127.0.0.1:3001";
  } else if (process.env.REACT_APP_BACKEND_ENV === "STAGING") {
    BASE_URL = "https://respond-staging-dot-phonic-2.wl.r.appspot.com";
  }
  return BASE_URL
}

export function titleCase(word) {
  if (!word) return null;
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

export function truncateStringWithEllipsis(title, trimLen) {
  let LENGTH = 28;
  if (trimLen) LENGTH = trimLen;
  if (typeof title !== "string" || title.length < LENGTH) return title;
  return title.substring(0, LENGTH - 3) + "...";
}

export function getBarColor(sentiment) {
  switch (sentiment) {
    case "POSITIVE":
      return "sentiment positive";
    case "NEGATIVE":
      return "sentiment negative";
    case "NEUTRAL":
      return "sentiment neutral";
    case "MIXED":
      return "sentiment mixed";
    default:
  }
}

export function getSentimentJSX(sentiment, response) {
  if (!sentiment) return <div></div>;
  const barColor = sentiment ? getBarColor(sentiment) : undefined;
  return (
    <div>
      <div>{sentiment.toUpperCase()}</div>
      <div
        className="progress-bar-xs progress"
        style={{ position: "relative" }}
      >
        <div
          className={barColor}
          style={{
            color: "black",
            paddingLeft: "10px",
            position: "absolute",
            top: "0",
            left: "0",
            fontSize: 10,
          }}
        >
          {Math.round(
            response.features.sentiment_score[titleCase(sentiment)] * 100
          )}
          %
        </div>
        <div
          className={`progress-bar ${barColor}`}
          role="progressbar"
          aria-valuenow="24"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{
            width: `${Math.abs(
              response.features.sentiment_score[titleCase(sentiment)] * 100
            )}%`,
          }}
        ></div>
      </div>
    </div>
  );
}

export function useDefaultIfUndef(value, def) {
  return value ? value : def;
}

export function copyToClipboard(str) {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
}

export const hHiLabels = [
  "0-10k",
  "10-25k",
  "25-50k",
  "50-75k",
  "75-100k",
  "100-125k",
  "125-150k",
  "150-175k",
  "175-200k",
  "200k +",
  "Prefer not to say",
];

export const chartColors = Object.values(rainbow);

export const conversationColors = [
  rainbow.blue,
  rainbow.green,
  rainbow.red,
  rainbow.violet,
  "#818c8e",
].map((color) => darken(color, 0.3));

export const getChartColors = (len) => {
  var colors = [];
  for (let i = 0; i < len; ++i)
    colors.push(chartColors[i % chartColors.length]);
  return colors;
};

export const getTransparentChartColors = (len, opacity) => {
  var colors = [];
  for (let i = 0; i < len; ++i)
    colors.push(changeOpacity(chartColors[i % chartColors.length], opacity));
  return colors;
};

/**
 * Return n elements from a list. If n > list length, add elements from the
 * beginning of the list. Mainly used with repeating colors.
 */
export const getNElements = (list, n) => {
  const result = [];
  for (let i = 0; i < n; ++i) result.push(list[i % list.length]);
  return result;
};

export function arrayMove(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}

export function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export function notification(title, text, status, duration = 2000) {
  store.addNotification({
    title: title,
    message: text,
    type: status,
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: duration,
    },
  });
}

export function getTypeJSX(type) {
  if (!type) return null;
  const parts = type.split("_");
  var tags = [];
  for (var i = 0; i < parts.length; i++) {
    var stateClass;
    switch (parts[i]) {
      case "AUDIO":
        stateClass = "type-tile audio";
        break;
      case "VIDEO":
        stateClass = "type-tile video";
        break;
      case "SCREEN":
        stateClass = "type-tile screen";
        break;
      case "NUMBER":
      case "SLIDER":
      case "FILE":
      case "TEXT":
      case "LIKERT":
        stateClass = "type-tile text";
        break;
      case "SELECTION":
      case "DROPDOWN":
      case "RANKING":
        stateClass = "type-tile selection";
        break;
      case "DISPLAY":
        stateClass = "type-tile display";
        break;
      default:
        stateClass = "type-tile selection";
    }
    tags.push(
      <Chip
        size="small"
        key={`Question-${stateClass}-${parts.toString()}`}
        label={parts[i]}
        className={stateClass}
      />
    );
  }
  return tags;
}

export function toTitleCase(string) {
  if (string == undefined) return "";
  return string.replace(/\S*/g, function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
}

export function getTypeJSXIcon(type, num) {
  const questionType = getQuestionType(type);
  if (!questionType) {
    return null;
  }
  return (
    <Tooltip title={toTitleCase(type.replace("_", " "))} aria-label={type}>
      <div
        className="qtype-tile shape-rect"
        style={{ backgroundColor: questionType.color }}
      >
        {questionType.icons.map((icon, idx) => {
          return <FontAwesomeIcon key={`ICON-${idx}-${num}`} icon={icon} />;
        })}{" "}
        &nbsp; {num}
      </div>
    </Tooltip>
  );
}

export function getTypeJSXIconSquare(type, num) {
  const questionType = getQuestionType(type);
  if (!questionType) {
    return null;
  }
  return (
    <div
      className="qtype-tile shape-square"
      style={{
        backgroundColor: `${questionType.color}`,
      }}
    >
      {questionType.icons.map((icon, idx) => {
        return <FontAwesomeIcon key={`ICON-${idx}-${num}`} icon={icon} />;
      })}
      &nbsp; {num}
    </div>
  );
}

export function getJSXIconSquare(key, num, style = {}) {
  const questionBuilderItem = getQuestionGroup(key) || getQuestionType(key);
  if (!questionBuilderItem) {
    return null;
  }

  return (
    <div
      className="qtype-tile-square"
      style={{
        ...style,
        backgroundColor: `${questionBuilderItem.color}`,
      }}
    >
      {questionBuilderItem.icons.map((icon, idx) => {
        return <FontAwesomeIcon key={`ICON-${idx}-${num}`} icon={icon} />;
      })}
    </div>
  );
}

export function getStimuliJSX(question) {
  if (!question || !question.stimuli) return;
  var chipIcon;
  switch (question.stimuli.type) {
    case "AUDIO":
      chipIcon = <GraphicEqIcon />;
      break;
    case "YOUTUBE":
      chipIcon = <YouTubeIcon />;
      break;
    case "IMAGE":
      chipIcon = <ImageIcon />;
      break;
    case "VIDEO":
      chipIcon = <VideocamIcon />;
      break;
    default:
  }
  return (
    <Chip
      size="small"
      icon={chipIcon}
      label={question.stimuli.type.replace("_", " ")}
      onClick={() => {
        window.open(question.stimuli.url, "_blank");
      }}
    />
  );
}

export function getStateJSX(survey, size = "small") {
  var stateWord = "",
    stateClass = "";
  if (survey.state === "PENDING") {
    stateWord = "Collecting";
    stateClass = "survey-pending";
  } else if (survey.state === "COMPLETED") {
    stateWord = "Completed";
    stateClass = "survey-done";
  } else if (survey.state === "DRAFT") {
    stateWord = "Draft";
    stateClass = "survey-draft";
  }
  return <Chip size={size} label={stateWord} className={stateClass} />;
}

export function getTimeStringFromSeconds(time) {
  const minutes = Math.floor((time % 3600) / 60)
    .toString()
    .padStart(2, 0);
  const seconds = Math.floor((time % 3600) % 60)
    .toString()
    .padStart(2, 0);
  return minutes + ":" + seconds;
}

export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function getObjectId() {
  return ObjectID().toString();
}

// Used for pagination when page is consumed from a query parameter.
// If it's an invalid number of non-existent, default to 1.
export function getPage(queryStr) {
  let q = queryString.parse(queryStr);
  if (q && q.page) return parseInt(q.page);
  return 1;
}

export function stringifyQuery(queryDict) {
  return queryString.stringify(queryDict);
}

export function parseQuery(query) {
  return queryString.parse(query);
}

export function getContrastYIQ(hexcolor, customThreshold) {
  if (!hexcolor) return "black";
  hexcolor = hexcolor.replace("#", "");
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= (customThreshold || 100) ? "black" : "white";
}

export function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

export function findListOfListsIndex(listOfLists, value) {
  for (let i = 0; i < listOfLists.length; ++i) {
    if (listOfLists[i].includes(value)) return i;
  }
  return -1;
}

export function isAudioType(type) {
  if (!type) return false;
  return type.includes("AUDIO") || type.includes("THINKALOUD");
}

export function isVideoType(type) {
  if (!type) return false;
  return type.includes("VIDEO") || type.includes("SCREEN");
}

export function isFileType(type) {
  if (!type) return false;
  return type.includes("FILE");
}

export function isTextTypeStrict(type) {
  if (!type) return false;
  return type.includes("TEXT");
}

export function isAudioOrVideoType(type) {
  if (!type) return false;
  return isAudioType(type) || isVideoType(type);
}

export function isOpenEndType(type) {
  if (!type) return false;
  return isAudioOrVideoType(type) || isTextTypeStrict(type);
}

export function isTitleOrFolderType(type) {
  if (!type) return false;
  return type.includes("TITLE") || type.includes("FOLDER");
}

export function isTitleType(type) {
  if (!type) return false;
  return type.includes("TITLE");
}

export function isSelectionNature(type) {
  if (!type) return false;
  return (
    type.includes("SELECTION") ||
    type.includes("DROPDOWN") ||
    type.includes("LIKERT") ||
    type.includes("RANKING")
  );
}

export function isSelectionNatureStrict(type) {
  if (!type) return false;
  return type.includes("SELECTION") || type.includes("DROPDOWN") || type.includes("LIKERT");
}

export function isSelectionType(type) {
  if (!type) return false;
  return type.includes("SELECTION");
}

export function isLikertType(type) {
  if (!type) return false;
  return type.includes("LIKERT");
}

export function isTextType(type) {
  if (!type) return false;
  return (
    type.includes("TEXT") ||
    type.includes("THINKALOUD") ||
    type.includes("NUMBER") ||
    type.includes("DATE") ||
    type.includes("SLIDER")
  );
}

export function isRankingType(type) {
  if (!type) return false;
  return type.includes("RANKING");
}

export function hasPlayScreen(response) {
  if (!response.uri) return false;
  return (
    ((isAudioType(response.type) || isVideoType(response.type)) &&
      response.backupText === undefined) ||
    (response.type === "FILE" &&
      (response.uri !== undefined || response.uris !== undefined))
  );
}

export function hasImageExtension(ext) {
  return [
    "jpg",
    "png",
    "gif",
    "webp",
    "tiff",
    "psd",
    "raw",
    "bmp",
    "heif",
    "indd",
    "jpeg",
    "svg",
  ].includes(ext);
}

export function getDemographics(
  demographics,
  showOptionalLabels /* optional labels are name and email */,
  filter = true
) {
  if (!demographics) demographics = {};
  return [
    {
      label: showOptionalLabels ? "Name" : null,
      _key: "name",
      value: [demographics.firstName, demographics.lastName]
        .filter((d) => d)
        .join(" "),
    },
    {
      label: showOptionalLabels ? "Email" : null,
      _key: "email",
      value: demographics.email ? demographics.email : null,
    },
    {
      label: "Age ",
      _key: "age",
      value: demographics.age ? demographics.age : null,
    },
    {
      label: "Gender ",
      _key: "gender",
      value: demographics.gender ? demographics.gender : null,
    },
    {
      label: "Household Income ",
      _key: "hHIncome",
      value: demographics.hHIncome ? demographics.hHIncome : null,
    },
    {
      label: "Location ",
      _key: "location",
      value: demographics.location ? demographics.location.display : null,
    },
  ].filter(filter ? (d) => d.value : () => true);
}

function ordinal_suffix_of(i) {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}

export function readableDate(date) {
  try {
    let parts = date.split(" ")[0].split("-");
    if (parts.length !== 3) return date;
    return `${monthNames[parseInt(parts[1]) - 1]} ${ordinal_suffix_of(
      parseInt(parts[2])
    )}, ${parts[0]}`;
  } catch (error) {
    return date;
  }
}

export function dateToString(date) {
  let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
  let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(date);
  let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
  return `${ye}-${mo}-${da}`;
}

export const wordCloudOptions = {
  colors: ["#8C8C8C", "#2C2C2C", "#8CC2B2", "#5F8BA8", "#394A61", "#2AC79F"],
  enableTooltip: true,
  deterministic: false,
  fontFamily: "Inter",
  fontSizes: [5, 60],
  fontStyle: "normal",
  fontWeight: "600",
  padding: 1,
  rotations: 3,
  rotationAngles: [0, 90],
  scale: "sqrt",
  spiral: "archimedean",
  transitionDuration: 1000,
};

export function getDemographicsBlock(response) {
  if (!response.demographics) return;
  return (
    <div className="demographics">
      {(response.demographics.firstName || response.demographics.lastName) && (
        <div className="font-size-s mb-5">
          <b>Name</b>:{" "}
          {response.demographics.firstName && (
            <span>{response.demographics.firstName}</span>
          )}{" "}
          {response.demographics.lastName && (
            <span>{response.demographics.lastName}</span>
          )}
        </div>
      )}
      {response.demographics.email && (
        <div className="font-size-s mb-5">
          <b>Email</b>: {response.demographics.email}
        </div>
      )}
      {response.demographics.age && (
        <div className="font-size-s mb-5">
          <b>Age</b>: {response.demographics.age}
        </div>
      )}
      {response.demographics.gender && (
        <div className="font-size-s mb-5">
          <b>Gender</b>: {response.demographics.gender}
        </div>
      )}
      {response.demographics.hHIncome && (
        <div className="font-size-s mb-5">
          <b>Household Income</b>: {response.demographics.hHIncome}
        </div>
      )}
      {response.demographics.location && (
        <div className="font-size-s mb-5">
          <b>Location</b>: {response.demographics.location.display}
        </div>
      )}
      <div className="font-size">
        <b>Date</b>: {readableDate(response.createdAt)}
      </div>
    </div>
  );
}

export function pushItemOntoArray(array, item) {
  if (!array) return [item];
  return [...array, item];
}

export function insertItemIntoArray(array, item, idx) {
  if (!array) return [item];
  let copyArray = [...array];
  copyArray.splice(idx, 0, item);
  return copyArray;
}

export function removeItemFromArray(array, idx) {
  if (!array) return [];
  return [...array.slice(0, idx), ...array.slice(idx + 1)];
}

export function updateItemInArray(array, idx, updatedItem) {
  if (!array) return [];
  return [...array.slice(0, idx), updatedItem, ...array.slice(idx + 1)];
}

export const getAverageSessionDuration = (sessions) => {
  if (!sessions || !Array.isArray(sessions)) return null;
  let sessionsWithDuration = sessions.length;

  const sum = sessions.reduce((sum, curr) => {
    const sessionDuration = curr.features.sessionDurationMs;
    if (sessionDuration) {
      return sum + sessionDuration;
    }
    sessionsWithDuration -= 1;
    return sum;
  }, 0);

  if (sessionsWithDuration === 0) return null;
  const value = sum / sessionsWithDuration;
  return (value / 1000).toFixed(1);
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const strFromDate = (dateObj) => {
  // JS Date object => YYYY/mm/dd
  const Y = `${dateObj.getFullYear()}`;
  const m = `${dateObj.getMonth() + 1}`.padStart(2, "0");
  const d = `${dateObj.getDate()}`.padStart(2, "0");
  return `${Y}-${m}-${d}`;
};

export const secondsToString = (seconds) => {
  let clock =
    Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0") +
    ":" +
    Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0") +
    ":" +
    Math.floor(seconds % 60)
      .toString()
      .padStart(2, "0");

  // If there are no hours.
  if (clock.substring(0, 3) === "00:") return clock.slice(3);
  return clock;
};

export const initialParams = (parsedQuery) => {
  if (typeof parsedQuery === "string") {
    return [parsedQuery];
  } else if (Array.isArray(parsedQuery)) {
    return parsedQuery;
  }
  return [];
};

export const blockFreeTier = (user) => {
  if (!user || user.tier === "FREE") {
    blockFreeTierNotification();
    return true;
  }
  return false;
};

export function blockFreeTierNotification() {
  notification(
    "Please upgrade to premium.",
    "This feature is only available on premium accounts.",
    "warning"
  );
}

export function isPositiveInteger(str) {
  return /^\+?(0|[1-9]\d*)$/.test(str);
}

export function formatCurrency(num) {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(num);
}

export function getJobStatusJSX(status) {
  if (status === "PENDING") return <CircularProgress size={12} />;
  if (status === "COMPLETED") return <FontAwesomeIcon icon={faCheck} />;
  if (status === "FAILED") return <FontAwesomeIcon icon={faCross} />;
  return status;
}

export function getSentenceAtTime(items, time) {
  if (!items || !time) return "";
  let sentence = "";
  for (let i = 0; i < items.length; ++i) {
    const item = items[i];
    if (item.end_time && parseInt(item.end_time) > time) {
      // Go back to previous punctuation
      let j = i;
      while (j >= 0) {
        if (
          items[j].type === "punctuation" ||
          (items[j].type === "generated" &&
            hasUtteranceEnd(itemContent(items[j])))
        )
          break;
        if (items[j] && items[j].alternatives)
          sentence = items[j].alternatives[0].content + " " + sentence;
        j--;
      }
      // Go forward to next punctuation
      j = i + 1;
      while (j < items.length) {
        if (items[j].type === "punctuation") break;
        sentence = sentence + " " + items[j].alternatives[0].content;
        if (
          items[j].type === "generated" &&
          hasUtteranceEnd(itemContent(items[j]))
        )
          break;
        j++;
      }
      break;
    }
  }
  return sentence;
}

export function launchHubspotWidget() {
  if (window.HubSpotConversations) {
    window.HubSpotConversations.widget.load();
  } else {
    window.hsConversationsOnReady = [
      () => {
        window.HubSpotConversations.widget.load();
      },
    ];
  }
}

export function surveyHasMedia(questions) {
  return (
    Array.isArray(questions) &&
    questions.some((q) => isAudioOrVideoType(q.type) && q.numResponses > 0)
  );
}

export function itemContent(item) {
  return item.alternatives[0].content;
}

export function isSpace(s) {
  return /\s/.test(s);
}

export function hasUtteranceEnd(s) {
  return /[;,.]/.test(s);
}

export function getSpaceItem() {
  return {
    type: "blankspace",
    alternatives: [{ content: " " }],
  };
}

export function getLastTimestamp(items) {
  for (let idx = items.length - 1; idx >= 0; --idx) {
    if (items[idx].end_time) {
      return items[idx].end_time;
    }
  }
}

export function getFirstTimestamp(items) {
  for (let idx = 0; idx < items.length; ++idx) {
    if (items[idx].start_time) {
      return items[idx].end_time;
    }
  }
}

function leftPad(number, targetLength) {
  var output = number + "";
  while (output.length < targetLength) {
    output = "0" + output;
  }
  return output;
}

export function getFormattedTime(time) {
  if (!time) return "";
  return `${leftPad(String(Math.floor(time / 60)), 2)}:${String(
    leftPad(Math.floor(time % 60), 2)
  )}`;
}

export function mapFlatFilesToTree(files) {
  const result = {};
  files.forEach((file) => {
    let root = result;
    file.path.split("/").forEach((part, idx, pathList) => {
      if (!root[part]) {
        root[part] = {
          ...file,
          type:
            idx === pathList.length - 1 && file.type !== "FOLDER"
              ? file.type
              : "FOLDER",
          name: part,
          children: {},
        };
      }
      root = root[part].children;
    });
  });
  return result;
}

/*
Merges 2 trees with 'name' and 'children' props
t2 overwrites changes to t1, so usually t1 is the original tree and t2 are the new nodes

The root of t1 and t2 are arrays.
Children are always assumed to exist (although they may be empty).
*/
export function mergeTrees(t1, t2) {
  for (const j in t2) {
    var found = false;
    for (const i in t1) {
      if (t1[i].name === t2[j].name) {
        t1[i] = {
          ...t2[j],
          ...t1[i],
          children: mergeTrees(t1[i].children, t2[j].children),
        };

        found = true;
      }
    }
    if (!found) {
      t1[j] = t2[j];
    }
  }
  return { ...t1 };
}

export function getTranscriptionItemsAsString(transcriptionItems) {
  if (!transcriptionItems) return "";
  return transcriptionItems
    .map((item, idx) => {
      if (item.type === "punctuation") {
        return item.alternatives[0].content;
      } else if (item.alternatives) {
        let innerResult = "";
        if (idx !== 0) {
          innerResult += " ";
        }
        innerResult += item.alternatives[0].content;
        return innerResult;
      }
    })
    .reduce((a, b) => a + b, "");
}

export function getTranscriptionHTML(items) {
  const transcription = getTranscriptionItemsAsString(items);
  return `${transcription}`;
}

export function printItems(items) {
  console.log(
    items.map((item) => {
      return itemContent(item);
    })
  );
}

export function notSpaceOrPunct(item) {
  if (!item) return false;
  return item.type !== "punctuation" && item.type !== "blankspace";
}

export function getDataset(params) {
  // Transform object: { surveyId, ... } => { "data-survey-id", ... }
  return Object.entries(params).reduce((obj, [k, v]) => {
    if (v) {
      const attr = `data-${kebabCase(k)}`;
      obj[attr] = v;
    } else if (k === "page" && v === 0) {
      const attr = `data-${kebabCase(k)}`;
      obj[attr] = v;
    }
    return obj;
  }, {});
}

export function getEmbedJSX(params) {
  const {
    phonicEmbed,
    surveyId,
    color,
    helperText,
    page,
    showOptions,
    preview,
  } = params;
  switch (params.phonicEmbed) {
    case "inline": {
      return (
        <>
          <div
            {...getDataset({
              phonicEmbed: "widget",
              surveyId,
              page,
              staging: !showOptions,
            })}
            /* This preview property is required by the external Phonic embed widget */
            // eslint-disable-next-line react/no-unknown-property
            preview={preview}
            style={{ width: "100%", height: 420, margin: "0 auto" }}
            id="launch-icon"
          />
          <script defer src="https://api.phonic.ai/survey-embed.min.js" />
        </>
      );
    }
    case "popover": {
      return (
        <>
          <a
            {...getDataset({
              phonicEmbed,
              surveyId,
              color,
              page,
              "start-open": !showOptions,
              staging: !showOptions,
            })}
            /* This preview property is required by the external Phonic embed widget */
            // eslint-disable-next-line react/no-unknown-property
            preview={preview}
            style={{
              position: "fixed",
              bottom: 20,
              right: 20,
              width: 48,
              height: 48,
              backgroundColor: `${params.color}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              boxShadow: "0 0 8px rgba(0, 0, 0, 0.15)",
              cursor: "pointer",
            }}
            tabIndex="0"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="22"
              height="25"
              viewBox="0 0 512 512"
              aria-hidden="true"
              focusable="false"
              id="launch-icon"
            >
              <path
                fill={getContrastYIQ(params.color, 170)}
                d="M416,21.333H96c-52.928,0-96,43.072-96,96V288c0,52.928,43.072,96,96,96h52.011c17.387,0,33.728,8.491,43.691,22.741 l55.552,79.36c2.005,2.859,5.269,4.565,8.747,4.565s6.741-1.707,8.747-4.544l55.552-79.36 C330.261,392.491,346.603,384,363.989,384H416c52.928,0,96-43.072,96-96V117.333C512,64.405,468.928,21.333,416,21.333z"
              />
            </svg>
          </a>
          <script defer src="https://api.phonic.ai/survey-embed.min.js" />
        </>
      );
    }
    case "sidedrawer": {
      return (
        <>
          <a
            {...getDataset({
              phonicEmbed,
              surveyId,
              color,
              helperText,
              page,
              "start-open": !showOptions,
              staging: !showOptions,
            })}
            /* This preview property is required by the external Phonic embed widget */
            // eslint-disable-next-line react/no-unknown-property
            preview={preview}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="22"
              height="25"
              viewBox="0 0 512 512"
              aria-hidden="true"
              focusable="false"
              id="launch-icon"
              tabIndex="0"
            >
              <path
                fill={getContrastYIQ(params.color, 170)}
                d="M416,21.333H96c-52.928,0-96,43.072-96,96V288c0,52.928,43.072,96,96,96h52.011c17.387,0,33.728,8.491,43.691,22.741 l55.552,79.36c2.005,2.859,5.269,4.565,8.747,4.565s6.741-1.707,8.747-4.544l55.552-79.36 C330.261,392.491,346.603,384,363.989,384H416c52.928,0,96-43.072,96-96V117.333C512,64.405,468.928,21.333,416,21.333z"
              />
            </svg>
          </a>
          <script defer src="https://api.phonic.ai/survey-embed.min.js" />
        </>
      );
    }
    default:
      return null;
  }
}

function filterDefaults(params, defaults) {
  let filtered = {};
  for (var field in params) {
    if (params[field] !== defaults[field]) {
      filtered[field] = params[field];
    }
  }
  return filtered;
}

function filterAttributes(params, allowedAttributes) {
  let filtered = {};
  for (var field in params) {
    if (field in allowedAttributes) {
      filtered[field] = params[field];
    }
  }
  return filtered;
}

export const VIDEO_EMBED_SUPPORTED_ATTRIBUTES = {
  spacing: "",
  showCounter: "",
  autoSubmit: "",
  recordText: "",
  rerecordText: "",
  submitText: "",
  doneText: "",
};

export function getQuestionEmbedJSX(params) {
  const { surveyId, questionId, type, prompt, style, behavior, text, preview } =
    params;
  var embedProps = {
    prompt,
    surveyid: surveyId,
    questionid: questionId,
    preview,
  };

  // Qualtrics response IDs can be consumed via this piped field.
  // Other integrations may be added below here.
  if (behavior.platform === "qualtrics") {
    embedProps.responseId = "${e://Field/ResponseID}";
  }

  if (isAudioOrVideoType(type)) {
    if (isAudioType(type)) {
      return (
        <>
          <phonic-embed
            {...embedProps}
            {...filterDefaults(style, getDefaultWidgetStyle())}
            {...filterDefaults(behavior, getDefaultWidgetBehavior())}
            {...{
              ...filterDefaults(text, getDefaultWidgetText()),
              ...(Object.keys(filterDefaults(text, getDefaultWidgetText()))
                .length !== 0 && { labelFormat: "custom" }),
            }}
          ></phonic-embed>
          <script
            type="text/javascript"
            src="https://api.phonic.ai/phonic-embed.min.js"
          ></script>
        </>
      );
    } else {
      return (
        <>
          <phonic-embed
            mode="video"
            {...embedProps}
            {...filterDefaults(
              filterAttributes(style, VIDEO_EMBED_SUPPORTED_ATTRIBUTES),
              getDefaultWidgetStyle()
            )}
            {...filterDefaults(
              filterAttributes(behavior, VIDEO_EMBED_SUPPORTED_ATTRIBUTES),
              getDefaultWidgetBehavior()
            )}
            {...{
              ...filterDefaults(
                filterAttributes(text, VIDEO_EMBED_SUPPORTED_ATTRIBUTES),
                getDefaultWidgetText()
              ),
              ...(Object.keys(filterDefaults(text, getDefaultWidgetText()))
                .length !== 0 && { labelFormat: "custom" }),
            }}
          ></phonic-embed>
          <script
            type="text/javascript"
            src="https://api.phonic.ai/phonic-embed.min.js"
          ></script>
        </>
      );
    }
  } else {
    return null;
  }
}

export function getAvatarUrl(user, size, optionalParams) {
  let displayName = user.displayName;
  if ((!displayName && user.firstName) || user.lastName) {
    displayName = user.firstName + " " + user.lastName;
  }
  let optionalStr = queryString.stringify(optionalParams);
  if (optionalStr) {
    optionalStr = "&" + optionalStr;
  }

  return `https://ui-avatars.com/api/?name=${displayName}&image_size=${size}${optionalStr}`;
}

export function removeHtmlTags(html) {
  if (!html) return null;
  return html.replace(/<[^>]*>/g, "");
}

export function hexToRgbA(hex, opacity) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      opacity +
      ")"
    );
  }
  throw new Error("Bad Hex");
}

export function changeOpacity(color, opacity) {
  return hexToRgbA(color, opacity);
}

export function getReadableSessionId(sid) {
  if (!sid) return "";
  return "#" + sid.substring(0, 6).toUpperCase();
}

export function getBestSessionTitles(s) {
  let result = ["", ""];
  if (s.createdAt) {
    result.push(readableDate(s.createdAt));
  }
  if (s.demographics) {
    if (s.demographics.email) {
      result.push(s.demographics.email);
    }
    if (s.demographics.firstName || s.demographics.lastName) {
      result.push(
        [s.demographics.firstName, s.demographics.lastName].join(" ")
      );
    }
  }
  return {
    text: result[result.length - 1],
    subText: result[result.length - 2],
  };
}

export function getFirstNameLastName(firstName, lastName) {
  let result = "";
  if (firstName) {
    result += firstName;
    if (lastName) result += " ";
  }
  if (lastName) result += lastName;
  return result;
}
/**
 * Add an item after every element in a array
 *
 * Example:
 * > interweave([1,2,3,4], 5)
 * [1,5,2,5,3,5,4]
 *
 * @param arr - Array to interweave
 * @param item - item to add
 * @returns a new array wih interweaved items
 */
export function interweave(arr, item) {
  return arr.reduce((a, b) => a.concat(item, b), []).slice(1);
}

export function debounceAction(
  updateTimeout,
  setUpdateTimeout,
  action,
  timeout = 1000
) {
  if (updateTimeout) {
    clearTimeout(updateTimeout);
  }
  setUpdateTimeout(setTimeout(() => action(), timeout));
}

export function extractNumbersFromString(str) {
  const nums = str.match(/-?\d+/g);
  if (!nums) return [];
  return nums.map((n) => parseInt(n, 10));
}

export function checkSurveyCount(user) {
  if (user.tier === "FREE" && user.surveys.length >= 3) {
    notification(
      "You've already created three surveys. To create more, consider upgrading your account to premium.",
      "Too many surveys.",
      "warning"
    );
    return false;
  }
  return true;
}

export function getFileFromTree(tree, filePath) {
  if (filePath.indexOf("/") !== -1 && tree) {
    tree = tree[filePath.substring(0, filePath.indexOf("/"))];
    filePath = filePath.substring(filePath.indexOf("/") + 1);
  }
  while (filePath.indexOf("/") !== -1 && tree) {
    tree = tree.children[filePath.substring(0, filePath.indexOf("/"))];
    filePath = filePath.substring(filePath.indexOf("/") + 1);
  }
  return tree.children[filePath];
}
// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export function hexToRgb(hex) {
  if (!hex) return null;
  return hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (r, g, b) => `#${r}${r}${g}${g}${b}${b}`
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));
  // hexToRgb("#0033ff") // [0, 51, 255]
}

export function QuestionDropDownItem(index, text, type) {
  return (
    <div className="question-dropdown-item">
      <div className="question-dropdown-body">
        <b>{index}.</b>
        <span style={{ width: 20 }}> </span>
        {text}
      </div>
      <div className={`question-dropdown-chip ${type}`}>{type}</div>
    </div>
  );
}

export function dataToCrosstabs(data, metadata) {
  var crosstab = [];
  //has an extra 2 rows and columns for non-response and row/column totals
  for (let i = 0; i < metadata[0].labels.length + 2; i++) {
    let rowList = [];
    for (let j = 0; j < metadata[1].labels.length + 2; j++) {
      rowList.push(0);
    }
    crosstab.push(rowList);
  }
  let nullRow = metadata[0].labels.length;
  let nullCol = metadata[1].labels.length;
  let totalsRow = nullRow + 1;
  let totalsCol = nullCol + 1;
  for (let idx in data) {
    let session = data[idx];
    if (session[0] === null && session[1] === null) {
      crosstab[nullRow][nullCol]++;
      crosstab[totalsRow][nullCol]++;
      crosstab[nullRow][totalsCol]++;
    } else if (session[0] === null) {
      for (let j = 0; j < session[1].length; j++) {
        if (session[1][j] === 1) {
          crosstab[nullRow][j]++;
          crosstab[totalsRow][j]++;
        }
      }
      crosstab[nullRow][totalsCol]++;
    } else if (session[1] === null) {
      for (let i = 0; i < session[0].length; i++) {
        if (session[0][i] === 1) {
          crosstab[i][nullCol]++;
          crosstab[i][totalsCol]++;
        }
      }
      crosstab[totalsRow][nullCol]++;
    } else {
      let var1selections = [];
      let var2selections = [];
      for (let i = 0; i < session[0].length; i++) {
        if (session[0][i]) {
          var1selections.push(i);
        }
      }
      for (let j = 0; j < session[1].length; j++) {
        if (session[1][j]) {
          var2selections.push(j);
        }
      }
      for (let x = 0; x < var1selections.length; x++) {
        crosstab[var1selections[x]][totalsCol]++;
        for (let y = 0; y < var2selections.length; y++) {
          crosstab[var1selections[x]][var2selections[y]]++;
        }
      }
      for (let y = 0; y < var2selections.length; y++) {
        crosstab[totalsRow][var2selections[y]]++;
      }
    }
    crosstab[totalsRow][totalsCol]++;
  }
  return crosstab;
}
