import React from "react";
import {
  faTimes,
  faCode,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select, TextField, MenuItem, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { questionTypes } from "utils/questionTypes";
import PropCheckbox from "../../components/SurveyCreation/PropCheckbox";
import {
  supportedLanguages,
  amazonComprehendSupportedLanguages,
} from "utils/data";
import {
  isAudioType,
  isAudioOrVideoType,
  isTextTypeStrict,
  isRankingType,
  isSelectionType,
  isLikertType,
  isSelectionNatureStrict,
  findListOfListsIndex,
  range,
} from "utils/utils";
import QuestionJavascriptPopup from "components/Popups/QuestionJavascriptPopup";
import { notification } from "utils/utils";

import LikertConfig from "./QuestionConfig/LikertOptionsConfig";

class QuestionConfiguration extends React.Component {
  state = { qinfo: {} };

  /**
   * NOTE: This function is deprecated. Use `updateQInfo` to make question updates.
   * @param {*} prop The question property to update.
   * @param {*} value Value applied to question property.
   * @param {*} refreshPreview Whether or not the application should save once edits are made.
   */
  changeQInfo = (prop, value, refreshPreview = true) => {
    const qinfo = {
      ...this.props.qinfo,
      [prop]: value,
    };

    /* Amazon Comprehend (which performs sentiment and topics) only supports a
     * subset of the available transcription languages */
    if (!this.isLangSupportedByAmazonComprehend(qinfo.transcriptionLanguage)) {
      qinfo.doSentiment = false;
      qinfo.doTopics = false;
    }

    this.props.changeQInfo(qinfo, this.props.id, refreshPreview);
  };

  /**
   * Replacement for `changeQInfo` which allows for multiple edits at once.
   * TODO: Replace all occurrences of the above with this function.
   * @param {*} updates Dictionary containing the updates to be made.
   * @param {*} refreshPreview Whether or not the application should save once edits are made.
   */
  updateQInfo = (updates, refreshPreview = true) => {
    const qinfo = {
      ...this.props.qinfo,
      ...updates,
    };

    /* Amazon Comprehend (which performs sentiment and topics) only supports a
     * subset of the available transcription languages */
    if (!this.isLangSupportedByAmazonComprehend(qinfo.transcriptionLanguage)) {
      qinfo.doSentiment = false;
      qinfo.doTopics = false;
    }

    this.props.changeQInfo(qinfo, this.props.id, refreshPreview);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.setState({ qinfo: {} });
    }
  }

  updateQuestionPropWithTimedUpdate = (prop, value) => {
    if (this.state.updateTimeout) {
      clearTimeout(this.state.updateTimeout);
    }
    this.setState({
      qinfo: {
        ...this.state.qinfo,
        [prop]: value,
      },
      updateTimeout: setTimeout(() => {
        this.changeQInfo(prop, value);
      }, 500),
    });
  };

  getQInfoIfMissingState = (key) => {
    return this.state.qinfo[key]
      ? this.state.qinfo[key]
      : this.props.qinfo[key];
  };

  getQuestionSelection = () => {
    return (
      <Select
        variant="outlined"
        fullWidth
        margin="dense"
        value={this.props.qinfo.type}
        onChange={(e) => this.changeQInfo("type", e.target.value, true)}
        disabled={this.props.surveyInfo.state !== "DRAFT"}
      >
        {questionTypes.filter(type => type.deprecated == false).map((type) => {
          if (type.key === "WELCOME") return null;
          return (
            <MenuItem value={type.key} key={type.key}>
              <span className="type-selection-logos">
                {type.icons.map((icon, idx) => {
                  return (
                    <FontAwesomeIcon
                      key={`ICON-${idx}`}
                      style={{ marginLeft: 3, marginRight: 3 }}
                      icon={icon}
                    />
                  );
                })}
              </span>
              &nbsp;&nbsp;<b>{type.name}</b> : {type.description}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  removeFromRandomGroup = () => {
    const questionId = this.props.qinfo.questionId;
    // Convert the lists into sets for simplicity
    let randomGroups = this.props.surveyInfo.randomGroups.map((group) => {
      return new Set(group);
    });
    // Get the index of the current question random group & delete
    let sourceIdx = -1;
    randomGroups.forEach((group, idx) => {
      if (group.has(questionId)) sourceIdx = idx;
    });
    if (sourceIdx === -1) {
      return;
    }
    randomGroups[sourceIdx].delete(questionId);
    // Remove if the set is now empty
    if (randomGroups[sourceIdx].size === 0) {
      randomGroups.splice(sourceIdx, 1);
    }
    // Back to lists and update
    randomGroups = randomGroups.map((group) => {
      return Array.from(group);
    });
    this.props.changeInfo({ randomGroups: randomGroups });
  };

  changeRandomGroup = (e) => {
    const questionId = this.props.qinfo.questionId;
    // Convert the lists into sets for simplicity
    let randomGroups = this.props.surveyInfo.randomGroups.map((group) => {
      return new Set(group);
    });
    // Get the index of the current question random group
    let sourceIdx = -1;
    randomGroups.forEach((group, idx) => {
      if (group.has(questionId)) sourceIdx = idx;
    });
    if (sourceIdx === e.target.value) return;
    if (sourceIdx !== -1) {
      randomGroups[sourceIdx].delete(questionId);
    }
    if (e.target.value >= randomGroups.length) {
      randomGroups.push(new Set([questionId]));
    } else if (e.target.value !== -1) {
      randomGroups[e.target.value].add(questionId);
    }
    // Remove if the set is now empty
    if (sourceIdx !== -1 && randomGroups[sourceIdx].size === 0) {
      randomGroups.splice(sourceIdx, 1);
    }
    // Back to lists and update
    randomGroups = randomGroups.map((group) => {
      return Array.from(group);
    });
    this.props.changeInfo({ randomGroups: randomGroups });
  };

  saveCustomScript = (code) => {
    this.updateQInfo(
      {
        customScript: code,
        enableCustomScript: code !== "" && code !== undefined,
      },
      true
    );
    this.setState({ javascriptPopupOpen: false });
  };

  /**
   * Returns whether a given language code is supported by Amazon Comprehend.
   *  Options to detect sentiment and entities within transcribed responses are
   *  disabled for languages which aren't supported by Comprehend.
   * @param {string} langCode Language code with region (e.g. en-US)
   * @returns {boolean} True if language is supported, False otherwise
   */
  isLangSupportedByAmazonComprehend = (langCode) => {
    const twoLetterCode = langCode.split("-")[0];
    return (
      amazonComprehendSupportedLanguages.includes(langCode) ||
      amazonComprehendSupportedLanguages.includes(twoLetterCode)
    );
  };

  render() {
    const langSupportedByComprehend = this.isLangSupportedByAmazonComprehend(
      this.props.qinfo.transcriptionLanguage
    );
    const disableAmazonComprehendProps =
      !this.props.qinfo.doTranscription || !langSupportedByComprehend;

    const showMinMaxTextLengthControls =
      isTextTypeStrict(this.props.qinfo.type) ||
      (isAudioOrVideoType(this.props.qinfo.type) &&
        this.props.qinfo.allowBackupText);
    
    return (
      <div id="question-settings">
        <QuestionJavascriptPopup
          open={this.state.javascriptPopupOpen}
          onSave={this.saveCustomScript}
          onClose={() => this.setState({ javascriptPopupOpen: false })}
          customScript={this.props.qinfo.customScript}
        />
        <span className="x-button">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={this.props.closeQuestionConfig}
          />
        </span>
        <h3>
          {this.props.qinfo.groupId === undefined
            ? "General"
            : "No Settings Available"}
        </h3>
        {this.props.qinfo.groupId === undefined ? (
          <>
            <div style={{ marginBottom: 10 }}>
              {this.getQuestionSelection()}
            </div>
            {this.props.surveyInfo.type !== "DISPLAY" && (
              <div className="transcription-options">
                <PropCheckbox
                  label={{
                    text: "Mark Optional",
                    placement: "right",
                  }}
                  update={(e) =>
                    this.changeQInfo("optional", e.target.checked, true)
                  }
                  value={this.props.qinfo.optional}
                  disabled={false}
                />
              </div>
            )}
            <div className="transcription-options">
              <PropCheckbox
                label={{
                  text: (
                    <span className="documentation-help">
                      Assign{" "}
                      <a
                        href="https://docs.phonic.ai/docs/using-data-mapping"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Data Label
                      </a>
                    </span>
                  ),
                  placement: "right",
                }}
                update={(e) => {
                  var value = e.target.checked ? true : undefined;
                  this.changeQInfo("assignDatamap", value, true);
                }}
                value={Boolean(this.props.qinfo.assignDatamap !== undefined)}
              />
              {this.props.qinfo.assignDatamap && (
                <TextField
                  key={`DATAMAP-${this.props.qinfo.questionId}`}
                  className="timer-selector"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  value={this.getQInfoIfMissingState("datamap")}
                  onChange={(e) =>
                    this.updateQuestionPropWithTimedUpdate(
                      "datamap",
                      e.target.value
                    )
                  }
                  style={{ marginBottom: 16, marginTop: 0 }}
                />
              )}
            </div>
            <div className="transcription-options">
              <PropCheckbox
                label={{
                  text: "Time Minimum (sec)",
                  placement: "right",
                }}
                update={(e) =>
                  this.changeQInfo("includeCountdownTimer", e.target.checked)
                }
                value={this.props.qinfo.includeCountdownTimer ? true : false}
                disabled={false}
              />
              {this.props.qinfo.includeCountdownTimer && (
                <TextField
                  className="timer-selector"
                  variant="outlined"
                  margin="dense"
                  placeholder="Seconds"
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      this.updateQuestionPropWithTimedUpdate(
                        "countdownTimer",
                        e.target.value
                      );
                    }
                  }}
                  value={this.getQInfoIfMissingState("countdownTimer")}
                  disabled={!this.props.qinfo.includeCountdownTimer}
                  inputProps={{ style: { textAlign: "right" } }}
                />
              )}
            </div>
            {showMinMaxTextLengthControls && (
              <div className="transcription-options">
                <PropCheckbox
                  label={{
                    text: isAudioOrVideoType(this.props.qinfo.type)
                      ? "Minimum Backup Text Length"
                      : "Minimum Text Length",
                    placement: "right",
                  }}
                  update={(e) =>
                    this.changeQInfo(
                      "minTextLength",
                      e.target.checked ? 100 : null
                    )
                  }
                  value={this.props.qinfo.minTextLength ? true : false}
                  disabled={false}
                />
                {this.props.qinfo.minTextLength && (
                  <TextField
                    className="timer-selector"
                    variant="outlined"
                    margin="dense"
                    placeholder="Seconds"
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === "" || re.test(e.target.value)) {
                        this.updateQuestionPropWithTimedUpdate(
                          "minTextLength",
                          e.target.value
                        );
                      }
                    }}
                    value={this.getQInfoIfMissingState("minTextLength")}
                    disabled={!this.props.qinfo.minTextLength}
                    inputProps={{ style: { textAlign: "right" } }}
                  />
                )}
              </div>
            )}
            {showMinMaxTextLengthControls && (
              <div className="transcription-options">
                <PropCheckbox
                  label={{
                    text: isAudioOrVideoType(this.props.qinfo.type)
                      ? "Maximum Backup Text Length"
                      : "Maximum Text Length",
                    placement: "right",
                  }}
                  update={(e) =>
                    this.changeQInfo(
                      "maxTextLength",
                      e.target.checked ? 200 : null
                    )
                  }
                  value={this.props.qinfo.maxTextLength ? true : false}
                  disabled={false}
                />
                {this.props.qinfo.maxTextLength && (
                  <TextField
                    className="timer-selector"
                    variant="outlined"
                    margin="dense"
                    placeholder="Seconds"
                    onChange={(e) => {
                      const re = /^[0-9\b]+$/;
                      if (e.target.value === "" || re.test(e.target.value)) {
                        this.updateQuestionPropWithTimedUpdate(
                          "maxTextLength",
                          e.target.value
                        );
                      }
                    }}
                    value={this.getQInfoIfMissingState("maxTextLength")}
                    disabled={!this.props.qinfo.maxTextLength}
                    inputProps={{ style: { textAlign: "right" } }}
                  />
                )}
              </div>
            )}
            <hr />
            {isAudioOrVideoType(this.props.qinfo.type) && (
              <>
                <h3>Recording</h3>
                <div>
                  <div className="transcription-options">
                    <PropCheckbox
                      label={{
                        text: "Computer Transcription",
                        placement: "right",
                      }}
                      update={(e) =>
                        this.changeQInfo("doTranscription", e.target.checked)
                      }
                      value={this.props.qinfo.doTranscription}
                    />
                    <Select
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      value={
                        this.props.qinfo.transcriptionLanguage
                          ? this.props.qinfo.transcriptionLanguage
                          : "en-US"
                      }
                      disabled={!this.props.qinfo.doTranscription}
                      onChange={(e) =>
                        this.changeQInfo(
                          "transcriptionLanguage",
                          e.target.value
                        )
                      }
                    >
                      {supportedLanguages.map((l) => {
                        return (
                          <MenuItem key={l.code} value={l.code}>
                            {l.text}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="transcription-options">
                    <PropCheckbox
                      label={{
                        text: "Text Backup",
                        placement: "right",
                      }}
                      update={(e) =>
                        this.changeQInfo("allowBackupText", e.target.checked)
                      }
                      value={this.props.qinfo.allowBackupText}
                    />
                  </div>
                  {isAudioType(this.props.qinfo.type) && (
                    <>
                      <div className="transcription-options">
                        <PropCheckbox
                          label={{
                            text: "Record Immediately",
                            placement: "right",
                          }}
                          update={(e) =>
                            this.changeQInfo("startRecording", e.target.checked)
                          }
                          value={this.props.qinfo.startRecording}
                        />
                      </div>
                      <div className="transcription-options">
                        <PropCheckbox
                          label={{
                            text: "Audio Playback",
                            placement: "right",
                          }}
                          update={(e) =>
                            this.changeQInfo(
                              "showAudioPlayback",
                              e.target.checked
                            )
                          }
                          value={this.props.qinfo.showAudioPlayback}
                        />
                      </div>
                    </>
                  )}
                  <div className="transcription-options">
                    <PropCheckbox
                      label={{
                        text: "Maximum Duration (sec)",
                        placement: "right",
                      }}
                      update={(e) =>
                        this.changeQInfo(
                          "limitResponseLength",
                          e.target.checked
                        )
                      }
                      value={Boolean(this.props.qinfo.limitResponseLength)}
                    />
                    {this.props.qinfo.limitResponseLength === true && (
                      <TextField
                        className="timer-selector"
                        variant="outlined"
                        margin="dense"
                        onChange={(e) => {
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            this.updateQuestionPropWithTimedUpdate(
                              "maxResponseLength",
                              e.target.value
                            );
                          }
                        }}
                        value={this.getQInfoIfMissingState("maxResponseLength")}
                        disabled={!this.props.qinfo.limitResponseLength}
                        inputProps={{ style: { textAlign: "right" } }}
                      />
                    )}
                  </div>
                </div>
                <hr />
              </>
            )}
            {(isSelectionNatureStrict(this.props.qinfo.type) && !isLikertType(this.props.qinfo.type)) && (
              <>
                <h3>Selection</h3>
                <div className="transcription-options">
                  <PropCheckbox
                    label={{
                      text: "Multiple Selections",
                      placement: "right",
                    }}
                    update={(e) =>
                      this.changeQInfo("exclusive", !e.target.checked)
                    }
                    value={!this.props.qinfo.exclusive}
                  />
                </div>
                <div className="transcription-options">
                  <PropCheckbox
                    label={{
                      text: "Randomize Ordering",
                      placement: "right",
                    }}
                    update={(e) =>
                      this.changeQInfo("randomize", e.target.checked)
                    }
                    value={this.props.qinfo.randomize}
                  />
                </div>
                <div className="transcription-options">
                  <PropCheckbox
                    label={{
                      text: "Min # Selections",
                      placement: "right",
                    }}
                    update={(e) =>
                      this.changeQInfo(
                        "minNumSelections",
                        e.target.checked ? 1 : null
                      )
                    }
                    value={Boolean(this.props.qinfo.minNumSelections)}
                    disabled={false}
                  />
                  {this.props.qinfo.minNumSelections && (
                    <TextField
                      className="timer-selector"
                      type='number'
                      variant="outlined"
                      margin="dense"
                      placeholder="Seconds"
                      onChange={({target}) => {
                        const val = Number(target.value);
                        this.updateQuestionPropWithTimedUpdate(
                          "minNumSelections", 
                          val ? val : null
                        );
                      }}
                      value={this.getQInfoIfMissingState("minNumSelections")}
                      disabled={!this.props.qinfo.minNumSelections}
                      inputProps={{ style: { textAlign: "right" } }}
                    />
                  )}
                </div>
                <div className="transcription-options">
                  <PropCheckbox
                    label={{
                      text: "Max # Selections",
                      placement: "right",
                    }}
                    update={(e) =>
                      this.changeQInfo(
                        "maxNumSelections",
                        e.target.checked ? 3 : null
                      )
                    }
                    value={Boolean(this.props.qinfo.maxNumSelections)}
                    disabled={false}
                  />
                  {this.props.qinfo.maxNumSelections && (
                    <TextField
                      className="timer-selector"
                      type='number'
                      variant="outlined"
                      margin="dense"
                      placeholder="Seconds"
                      onChange={({target}) => {
                        const val = Number(target.value);
                        this.updateQuestionPropWithTimedUpdate(
                          "maxNumSelections",
                          val ? val : null
                        );
                      }}
                      value={this.getQInfoIfMissingState("maxNumSelections")}
                      disabled={!this.props.qinfo.maxNumSelections}
                      inputProps={{ style: { textAlign: "right" } }}
                    />
                  )}
                </div>
                {isSelectionType(this.props.qinfo.type) && (
                  <div className="transcription-options">
                    <PropCheckbox
                        label={{
                          text: "Show Selection Icons",
                          placement: "right",
                        }}
                        update={(e) =>
                            this.changeQInfo("showSelectionIcons", e.target.checked)
                        }
                        value={this.props.qinfo.showSelectionIcons !== false}
                    />
                  </div>
                )}
                <hr />
              </>
            )}
            {isRankingType(this.props.qinfo.type) && (
              <>
                <h3>Ranking</h3>
                <div className="transcription-options">
                  <PropCheckbox
                    label={{
                      text: "Randomize Ordering",
                      placement: "right",
                    }}
                    update={(e) =>
                      this.changeQInfo("randomize", e.target.checked)
                    }
                    value={this.props.qinfo.randomize}
                  />
                </div>
              </>
            )}
            {/* TODO: Add doTopics to text questions */}
            {isAudioOrVideoType(this.props.qinfo.type) && (
              <>
                <h3>Analysis</h3>
                {!langSupportedByComprehend &&
                  this.props.qinfo.doTranscription && (
                    <Alert
                      severity="warning"
                      className="comprehend-lang-not-supported-alert"
                    >
                      Analysis features are not supported for the selected
                      transcription language
                    </Alert>
                  )}
                <div className="transcription-options">
                  <PropCheckbox
                    label={{
                      text: "Sentiment Analysis",
                      placement: "right",
                    }}
                    update={(e) =>
                      this.changeQInfo("doSentiment", e.target.checked)
                    }
                    value={this.props.qinfo.doSentiment}
                    disabled={disableAmazonComprehendProps}
                  />
                </div>
                <hr />
              </>
            )}
            {this.props.qinfo.type.includes("SLIDER") && (
              <>
                <h3>Bounds</h3>
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  label="Lower Bound"
                  value={this.getQInfoIfMissingState("bounds").lower}
                  onChange={(e) =>
                    this.updateQuestionPropWithTimedUpdate("bounds", {
                      ...this.props.qinfo.bounds,
                      lower: e.target.value,
                    })
                  }
                  style={{ marginBottom: 16, marginTop: 0 }}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  label="Lower Bound Label"
                  value={this.getQInfoIfMissingState("bounds").lowerLabel}
                  onChange={(e) =>
                    this.updateQuestionPropWithTimedUpdate("bounds", {
                      ...this.props.qinfo.bounds,
                      lowerLabel: e.target.value,
                    })
                  }
                  style={{ marginBottom: 16, marginTop: 0 }}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  label="Upper Bound"
                  value={this.getQInfoIfMissingState("bounds").upper}
                  onChange={(e) =>
                    this.updateQuestionPropWithTimedUpdate("bounds", {
                      ...this.props.qinfo.bounds,
                      upper: e.target.value,
                    })
                  }
                  style={{ marginBottom: 16, marginTop: 0 }}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  label="Upper Bound Label"
                  value={this.getQInfoIfMissingState("bounds").upperLabel}
                  onChange={(e) =>
                    this.updateQuestionPropWithTimedUpdate("bounds", {
                      ...this.props.qinfo.bounds,
                      upperLabel: e.target.value,
                    })
                  }
                  style={{ marginBottom: 16, marginTop: 0 }}
                />
                <TextField
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  label="Interval"
                  value={this.getQInfoIfMissingState("bounds").interval}
                  onChange={(e) =>
                    this.updateQuestionPropWithTimedUpdate("bounds", {
                      ...this.props.qinfo.bounds,
                      interval: e.target.value,
                    })
                  }
                  style={{ marginBottom: 16, marginTop: 0 }}
                />
                <hr />
              </>
            )}
            {this.props.qinfo.type === "SCREEN" && (
              <>
                <h3>Screen Recording</h3>
                <div>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    label="Redirect Link (Ex. https://phonic.ai)"
                    value={this.state.redirectLink || this.props.redirectLink}
                    onChange={(e) =>
                      this.updateQuestionPropWithTimedUpdate(
                        "redirectLink",
                        e.target.value
                      )
                    }
                    style={{ marginBottom: 16, marginTop: 0 }}
                  />
                </div>
                <hr />
              </>
            )}
            {this.props.qinfo.type === "LIKERT" && (
              <>
                <h3>Likert</h3>
                <div>
                  <PropCheckbox
                    label={{
                      text: "Randomize Order",
                      placement: "right",
                    }}
                    update={(e) => this.changeQInfo("randomize", e.target.checked) }
                    value={this.props.qinfo.randomize}
                  />
                </div>
                <LikertConfig { ...this.props } 
                  getQInfoIfMissingState={(key) => this.getQInfoIfMissingState(key) }
                  updateQInfo={(update, refresh) => this.updateQInfo(update, refresh) }
                />
                <hr/>
              </>
            )}
            <h3>Randomization</h3>
            <div className="info-text">
              <p>
                Randomize questions in groups. Learn more about group
                randomization in our{" "}
                <a href="https://docs.phonic.ai/docs/randomization">
                  documentation
                </a>
              </p>
            </div>
            <Select
              fullWidth
              variant="outlined"
              margin="dense"
              disabled={this.props.tier === "FREE"}
              value={findListOfListsIndex(
                this.props.surveyInfo.randomGroups,
                this.props.qinfo.questionId
              )}
              onChange={this.changeRandomGroup}
            >
              <MenuItem value={-1}>None</MenuItem>
              {range(0, this.props.surveyInfo.randomGroups.length - 1).map(
                (i) => {
                  return (
                    <MenuItem key={`GROUP-${i}`} value={i}>
                      Group {i + 1}
                    </MenuItem>
                  );
                }
              )}
              <MenuItem value={this.props.surveyInfo.randomGroups.length}>
                New Group
              </MenuItem>
            </Select>
            <hr />
            <h3>Question Javascript</h3>
            <div className="info-text">
              <p>
                Run a script when this question is shown. Learn more about
                question javascript in our{" "}
                <a href="https://docs.phonic.ai/docs/custom-javascript">
                  documentation
                </a>
              </p>
              <Button
                className="phonic-outlined-button"
                variant="outlined"
                color="primary"
                onClick={() => {
                  if (this.props.tier !== "ENTERPRISE") {
                    notification(
                      "This feature is not included on your current tier",
                      "Please upgrade to continue",
                      "warning"
                    );
                    return;
                  }
                  this.setState({ javascriptPopupOpen: true });
                }}
              >
                <FontAwesomeIcon icon={faCode} /> &nbsp;Javascript Editor
              </Button>
            </div>
          </>
        ) : (
          <div>Questions inside a mission cannot be configured</div>
        )}
      </div>
    );
  }
}

export default QuestionConfiguration;
