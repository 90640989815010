import React, { Component } from "react";
import clsx from "clsx";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import {
  getPanelRequests,
  setPageTitle,
  createPanelRequest,
  deletePanelRequest,
} from "../redux/actions";
import { Grid, Button, Paper, Chip } from "@material-ui/core";
import { ClipLoader } from "react-spinners";
import CreatePanelRequestPopup from "components/Popups/CreatePanelRequestPopup";
import FeedbackPopup from "components/Popups/FeedbackPopup";
import { getBlankPanelRequest } from "utils/defaults";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { titleCase, notification, readableDate } from "utils/utils";
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

const mapStateToProps = (state) => {
  return {
    panelRequests: state.panelRequests,
    user: state.user,
    panelRequestsLoading: state.loading.MyPanelRequests,
    surveysLoading: state.loading.MySurveys,
  };
};

const mapDispatchToProps = {
  getPanelRequests,
  setPageTitle,
  createPanelRequest,
  deletePanelRequest,
};

class MyPanelRequests extends Component {
  state = {
    createPopupOpen: false,
    feedbackOpen: false,
  };

  componentDidMount() {
    this.props.getPanelRequests();
    this.props.setPageTitle();
    // Check if the top notification is regarding the panel tutorial
    if (this.props.user) {
      if (
        this.props.user.feedback &&
        this.props.user.feedback.length > 0 &&
        this.props.user.feedback[0].type === "PANEL_TUTORIAL"
      ) {
        this.setState({
          feedbackOpen: true,
          feedbackNotif: this.props.user.feedback[0],
        });
      }
    }
  }

  routeToEdit = (panelRequestId) => {
    this.props.history.push(`recruit/${panelRequestId}/create`);
  };
  createPanelRequestAndRedirect = (name) => {
    this.props.createPanelRequest(
      { ...getBlankPanelRequest(), name: name },
      (resp) => this.routeToEdit(resp._id)
    );
  };

  noPanelRequests = () => {
    if (
      this.props.panelRequests &&
      Object.keys(this.props.panelRequests).length === 0
    )
      return (
        <tr onClick={() => this.setState({ createPopupOpen: true })}>
          <td align="center" colSpan="7">
            <br />
            <img
              src={require("../assets/img/no_panel_requests.svg")}
              alt="No panel requests found."
              style={{ height: 200 }}
            ></img>
            <br />
            <br />
            <span>No panel requests yet.</span>
            {this.props.user && this.props.user.trialExpired === false && (
              <span>
                {" "}
                <b>Click here</b> to get started.
              </span>
            )}
          </td>
        </tr>
      );
  };

  render() {
    const { panelRequests } = this.props;

    return (
      <div className="content">
        <Helmet>
          <title>Recruit</title>
        </Helmet>
        <FeedbackPopup
          open={this.state.feedbackOpen}
          onClose={() => this.setState({ feedbackOpen: false })}
          feedbackNotif={this.state.feedbackNotif}
          submitFeedback={this.props.submitFeedback}
          loading={this.props.feedbackLoading}
          maxWidth="md"
        />
        <CreatePanelRequestPopup
          open={this.state.createPopupOpen}
          onClose={() => this.setState({ createPopupOpen: false })}
          createPanelRequest={this.createPanelRequestAndRedirect}
        />
        <Grid container className="screen-margin" spacing={2}>
          <Grid item xs={6}>
            <h1 className="page-title">Recruit</h1>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.setState({ createPopupOpen: true })}
            >
              + New Campaign
            </Button>
          </Grid>
        </Grid>
        <Grid container className="screen-margin">
          <Paper style={{ width: "100%" }}>
            {!this.props.panelRequestsLoading || this.props.panelRequests ? (
              <Table
                aria-label="panel-request-table"
                className="align-middle mb-0 table table-borderless table-striped table-hover"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Campaign Name</TableCell>
                    <TableCell>Study Name</TableCell>
                    <TableCell>Audiences</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Progress</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {panelRequests && (
                  <TableBody>
                    {panelRequests.map((pr) => (
                      <TableRow
                        key={`PANEL-REQUEST-${pr._id}`}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        onClick={() => {
                          if (pr.status !== "DRAFT")
                            notification(
                              "This recruit request is not in draft mode.",
                              "Please contact us if you need to make a change.",
                              "warning"
                            );
                          else this.routeToEdit(pr._id);
                        }}
                      >
                        <TableCell component="th">{pr.name}</TableCell>
                        <TableCell component="th">
                          {pr.surveyName ? pr.surveyName : "No Survey Selected"}
                        </TableCell>
                        <TableCell component="th">
                          {pr.audiences ? pr.audiences.length : 0}
                        </TableCell>
                        <TableCell component="th">
                          {pr.status && (
                            <Chip
                              size="small"
                              label={titleCase(pr.status)}
                              className={clsx(
                                "panel-request-chip",
                                pr.status.toLowerCase()
                              )}
                            />
                          )}
                        </TableCell>
                        <TableCell component="th">
                          {pr.collected !== undefined
                            ? `${pr.collected}/${pr.limit}`
                            : "-"}
                        </TableCell>
                        <TableCell component="th">{readableDate(pr.createdAt)}</TableCell>
                        <TableCell component="th">
                          <FontAwesomeIcon
                            className={clsx("hover-button")}
                            icon={faTrash}
                            onClick={(e) => {
                              e.stopPropagation();
                              this.props.deletePanelRequest(pr._id);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                    {this.noPanelRequests()}
                  </TableBody>
                )}
              </Table>
            ) : (
              <div className="flex-container" style={{ margin: 10 }}>
                <ClipLoader size={26} color={"gray"} />
              </div>
            )}
          </Paper>
          <div className="width-100p">
            <span
              className="link-text mt-10 float-right"
              onClick={() =>
                this.setState({
                  feedbackOpen: true,
                  feedbackNotif: { type: "PANEL_TUTORIAL" },
                })
              }
            >
              <FontAwesomeIcon icon={faQuestionCircle} /> Not sure where to
              start? Check out our tutorial.
            </span>
          </div>
        </Grid>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyPanelRequests);
